<template>
  <div class="info-wrapper">
    <p-header></p-header>
    <div class="banner">
      <img src="~common/images/info_top.png" width="100%" alt="">
      <div class="banner-title">北京创新一点通数码科技有限公司</div>
    </div>
    <div class="info-container">
      <div class="info-content-box">
        <div class="info-item-box">
          <p class="text">北京创新一点通数码科技有限公司，是一家为企业提供不同场景下与用户进行高效沟通解决方案的数据科技公司。通过物联网与互联网相结合的先进技术，依托⼀物⼀码技术，提供商品赋码、数字营销、追踪溯源、防伪防窜、会员运营等解决⽅案，为客户打造不同场景下人、货、场的沟通生态链，从而为企业实现经营目标赋能，共同链接智能未来。</p>
        </div>
        <div class="info-contact-box">
          <img src="~common/images/info_content.png" style="width:6rem;margin-bottom: 1rem;margin-top:0.3rem;" alt="">
        </div>
      </div>
    </div>
    <p-footer></p-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'

  export default {
    data() {
      return {}
    },
    /* eslint-disable no-undef */
    created() {
    },
    methods: {},
    watch: {},
    components: {
      PHeader,
      PFooter
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .info-wrapper
    width 100%
    min-width 1270px
    .banner
      padding-top: 0.35rem;
      position relative
      text-align: center
      width: 100%
      .banner-title
        position absolute
        top 1.1rem
        left 51%
        transform: translateX(-50%)
        font-size: 0.23rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.44rem;
    .info-container
      width 100%
      box-sizing border-box
      .info-content-box
        background: #F6FBFE;
        .info-item-box
          color #333
          text-align left
          box-sizing border-box
          padding .45rem 2.29rem .21rem 2.29rem
          .text
            font-size: 0.102rem;
            font-weight: 400;
            color: #191B31;
            line-height: 0.15rem;
            letter-spacing: 1px;
        .info-contact-box
          padding-bottom 0.3rem
          text-align center
          color #333
</style>
